import { useRef } from "react";
import { playAnswerAnimation, renderEmojis } from "./helpers";
import styles from "./Shauny.module.css";
import classnames from "classnames";
import Confetti from "react-confetti";
import { usePageLogic } from "./hooks";
import { useMediaQuery } from "react-responsive";

export const ShaunyPage = (): JSX.Element => {
  const containerRef = useRef(null);
  const cardsRef = useRef(null);
  const emojiRef = useRef(null);
  const is670 = useMediaQuery({ query: "(max-width: 670px)" });
  const {
    page,
    cardClicked,
    cardHidden,
    subCardHidden,
    nextButtonVisible,
    confetti,
    results,
    color,
    handleSubmit,
    toggleSubCard,
    nextPage,
    setCardHidden,
    setCardClicked,
    loggedIn,
    shuffledCARDS,
  } = usePageLogic({ containerRef, emojiRef });

  if (!loggedIn) {
    return (
      <div className={styles.loginRoot}>
        <h1>Top Secret</h1>
        <form action="" onSubmit={handleSubmit} className={styles.loginForm}>
          <label htmlFor="password">Password</label>
          <input name="password" type="password" autoComplete="off" />
        </form>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <h1>Shauny!</h1>
      <span
        ref={emojiRef}
        className={classnames(styles.emojiContainer, {
          [styles.emojiVisible]: nextButtonVisible || page === 4,
        })}
      >
        {renderEmojis(page)}
      </span>
      {is670 ||
        (page !== 4 && (
          <div className={styles.title}>
            {page === 0 ? <h2>Choose your birthday presents, stinky</h2> : ""}
          </div>
        ))}
      <div className={styles.container} ref={containerRef}>
        {shuffledCARDS.map(
          ({ step, subCards, question, subQuestion }, index) => {
            if (step === page) {
              return (
                <div key={index}>
                  <div
                    style={{ backgroundColor: color }}
                    className={classnames(styles.questionCard, {
                      [styles.clicked]: cardClicked[page],
                    })}
                    onClick={() => {
                      if (cardHidden[page]) {
                        setCardHidden({ ...cardHidden, [page]: false });
                      } else {
                        setCardClicked({ ...cardClicked, [page]: true });
                        if (!cardClicked[page]) {
                          playAnswerAnimation(cardsRef);
                        }
                      }
                    }}
                  >
                    {cardHidden[page] ? (
                      <h2>?</h2>
                    ) : (
                      <h2>
                        {question}
                        <br />
                        {subQuestion}
                      </h2>
                    )}
                  </div>
                  <div className={styles.answerCardsContainer} ref={cardsRef}>
                    {subCards?.map((subCard, idx) => {
                      const isClicked = subCardHidden[page][idx].clicked;
                      if (cardClicked[page]) {
                        return (
                          <div
                            key={idx}
                            style={
                              isClicked
                                ? { backgroundColor: color, opacity: 0.85 }
                                : {}
                            }
                            className={classnames(styles.answerCard, {
                              [styles.clicked]: nextButtonVisible,
                              [styles.lessWords]: page !== 3,
                            })}
                            onClick={() => toggleSubCard(page, idx)}
                          >
                            {isClicked ? (
                              <h3>{subCard.text}</h3>
                            ) : nextButtonVisible ? (
                              ""
                            ) : (
                              <h3 style={{ color: "#707070" }}>?</h3>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>

                  <div className={styles.buttons}>
                    {nextButtonVisible && (
                      <button onClick={nextPage}>Next</button>
                    )}
                  </div>
                </div>
              );
            }
            return null;
          }
        )}
      </div>

      {page === 4 && (
        <div className={styles.results}>
          {confetti && <Confetti numberOfPieces={120} />}
          <h2>Happy Birthday!</h2>
          <ul>
            {results.map(({ question, text }, index) => (
              <li key={index}>
                <h3>{question}</h3>
                <p>{text}</p>
              </li>
            ))}
          </ul>

          <h2>♥️ I LOVE YOU ♥️</h2>

          <button
            className={styles.printButton}
            onClick={() => {
              window.print();
            }}
          >
            Print the results!
          </button>
        </div>
      )}
    </div>
  );
};
