import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { PAGE_URLS } from "utils/urls";
import { HomePage } from "features/home/HomePage";
import { ShaunyPage } from "features/shauny/Shauny";
import { UserProvider } from "utils/context";

export default function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path={PAGE_URLS.baseurl} element={<HomePage />} />
          <Route path={PAGE_URLS.shauny} element={<ShaunyPage />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}
