import { useState, useEffect, useContext } from "react";
import { DataContext } from "utils/context";
import {
  CARDS,
  initialStateCardsClicked,
  initialStateSubCards,
  initialStateCardsHidden,
  getResults,
  updateButtonVisibility,
  getColor,
  playQuestionAnimation,
  playAnswerAnimation,
} from "./helpers";

export const usePageLogic = ({
  containerRef,
  emojiRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  emojiRef: React.RefObject<HTMLSpanElement>;
}) => {
  const [page, setPage] = useState(0);
  const [cardClicked, setCardClicked] = useState(initialStateCardsClicked);
  const [cardHidden, setCardHidden] = useState(initialStateCardsHidden);
  const [subCardHidden, setSubCardHidden] = useState(initialStateSubCards);
  const [nextButtonVisible, setNextButtonVisible] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [shuffledCARDS, setShuffledCARDS] = useState(CARDS);
  const { options, setOptions, loggedIn, setLoggedIn } =
    useContext(DataContext);

  useEffect(() => {
    const shuffleSubCards = (cards: typeof CARDS) => {
      return cards.map((card) => {
        return {
          ...card,
          subCards: [...card.subCards].sort(() => Math.random() - 0.5),
        };
      });
    };

    const updatedCARDS = shuffleSubCards(shuffledCARDS);
    setShuffledCARDS(updatedCARDS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("loggedIn");
    if (loggedInStatus === "true") {
      setLoggedIn(true);
    }
  }, [setLoggedIn]);

  const results = getResults(options);
  const color = getColor(page);

  useEffect(() => {
    if (page !== 4) {
      updateButtonVisibility(page, subCardHidden, setNextButtonVisible);
    } else {
      setConfetti(true);
      playAnswerAnimation(emojiRef);
    }
  }, [emojiRef, page, subCardHidden]);

  useEffect(() => {
    if (page === 0) {
      playQuestionAnimation(containerRef);
    }
  }, [containerRef, page]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const password = formData.get("password");
    if (password === process.env.REACT_APP_PASSWORD) {
      localStorage.setItem("loggedIn", "true");
      setLoggedIn(true);
    } else {
      alert("Incorrect password");
    }
  };

  const toggleSubCard = (step: number, index: number) => {
    if (!nextButtonVisible) {
      if (step === 0 || step === 3) {
        setOptions((prevState) => [
          ...prevState,
          shuffledCARDS[step].subCards[index].text,
        ]);
      }

      setSubCardHidden((prevState) => {
        const newState = { ...prevState };
        if (!newState[step]) newState[step] = [];

        newState[step] = newState[step].map((sub, idx) =>
          idx === index ? { ...sub, clicked: true } : sub
        );

        return newState;
      });
    }
  };

  const nextPage = () => {
    setPage((prevState) => prevState + 1);
    setCardClicked(initialStateCardsClicked);
    setCardHidden(initialStateCardsHidden);
    setSubCardHidden(initialStateSubCards);
    playQuestionAnimation(containerRef);
  };

  return {
    page,
    cardClicked,
    cardHidden,
    subCardHidden,
    nextButtonVisible,
    confetti,
    results,
    color,
    handleSubmit,
    toggleSubCard,
    nextPage,
    setCardHidden,
    setCardClicked,
    loggedIn,
    shuffledCARDS,
  };
};
