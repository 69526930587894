export const PAGE_URLS: Record<string, string> = {
  baseurl: "/",
  shauny: "/shauny",
};

export const PAGE_OPTIONS = [{ text: "about" }, { text: "work" }];

export enum PageNames {
  ABOUT = "about",
  WORK = "work",
}
