import { gsap } from "gsap";
import styles from "./Shauny.module.css";

export const CARDS = [
  {
    step: 0,
    question: "1. I will peel some pomegranates for you!",
    subQuestion: "Because I love you! ...how many?",
    subCards: [
      { text: "One Pomegranate" },
      { text: "Three Pomegranates" },
      { text: "Five Pomegranates" },
      { text: "Ten Pomegranates" },
    ],
  },
  {
    step: 1,
    question: "2. What are you getting this weekend?",
    subCards: [
      { text: "Massage" },
      { text: "I will cook for you" },
      { text: "😛" },
      { text: "We go on your favorite hike!" },
    ],
  },
  {
    step: 2,
    question: "3. Where are we going on an adventure?",
    subQuestion: "(you get to pick one)",
    subCards: [
      { text: "Skydiving" },
      { text: "Paragliding" },
      { text: "Hang Gliding" },
    ],
  },
  {
    step: 3,
    question: "4. Is it your lucky day today?",
    subCards: [
      {
        text: "Extra Lucky! I will say yes to EVERYTHING you want to do today",
      },
      {
        text: "Yes! You will get a Costco hotdog and we will have a mayo tasting party",
      },
      {
        text: "Yes! I will say yes to ONE thing that you want to do today",
      },
    ],
  },
];

export const initialStateSubCards: {
  [key: number]: { hidden: boolean; clicked: boolean }[];
} = {
  0: [
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
  ],
  1: [
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
  ],
  2: [
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
  ],
  3: [
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
    { hidden: false, clicked: false },
  ],
};

export const initialStateCardsClicked: { [key: number]: boolean } = {
  0: false,
  1: false,
  2: false,
  3: false,
};

export const initialStateCardsHidden: { [key: number]: boolean } = {
  0: true,
  1: true,
  2: true,
  3: true,
};

export const getResults = (options: string[]) => {
  const uniqueArray = [...new Set(options)];

  return [
    {
      question: CARDS[0].question,
      text: uniqueArray[0],
    },
    {
      question: CARDS[1].question,
      text: CARDS[1].subCards.map(({ text }) => text).join(", "),
    },
    {
      question: CARDS[2].question,
      text: CARDS[2].subCards.map(({ text }) => text).join(", "),
    },

    {
      question: CARDS[3].question,
      text: uniqueArray[1],
    },
  ];
};

export const updateButtonVisibility = (
  page: number,
  subCardHidden: {
    [key: number]: {
      [key: number]: boolean;
    }[];
  },
  setNextButtonVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let isVisible = false;

  if (page === 1 || page === 2) {
    isVisible = Object.values(subCardHidden[page]).every((subCard) =>
      Object.values(subCard).some((card) => card === true)
    );
  } else {
    isVisible = Object.values(subCardHidden[page]).some((subCard) =>
      Object.values(subCard).some((card) => card === true)
    );
  }

  setNextButtonVisible(isVisible);
};

const colors: { [key: number]: string } = {
  0: "#FB6A79",
  1: "#637DB4",
  2: "#53C0A1",
  3: "#F9A03F",
};

export const getColor = (page: number) => {
  return colors[page];
};

export const playQuestionAnimation = (
  containerRef: React.RefObject<HTMLDivElement>
) => {
  gsap.fromTo(
    containerRef.current,
    {
      x: 300,
      autoAlpha: 0,
    },
    {
      x: 0,
      autoAlpha: 1,
      duration: 0.7,
      ease: "power2.out",
    }
  );
};

export const playAnswerAnimation = (
  ref: React.RefObject<HTMLDivElement> | React.RefObject<HTMLSpanElement>
) => {
  gsap.fromTo(
    ref.current,
    {
      y: 300,
      autoAlpha: 0,
    },
    {
      y: 0,
      autoAlpha: 1,
      duration: 0.5,
      ease: "power2.out",
    }
  );
};

const emojiSets: {
  [key: number]: { emoji: string; position: string }[];
} = {
  0: [
    { emoji: "🫘", position: styles.emojiPosition2 },
    { emoji: "🍎", position: styles.emojiPosition3 },
    { emoji: "🍆", position: styles.emojiPosition4 },
    { emoji: "🍇", position: styles.emojiPosition5 },
    { emoji: "🍑", position: styles.emojiPosition6 },
  ],
  1: [
    { emoji: "💆🏼", position: styles.emojiPosition2 },
    { emoji: "🥐", position: styles.emojiPosition3 },
    { emoji: "👅", position: styles.emojiPosition4 },
    { emoji: "🥾", position: styles.emojiPosition5 },
    { emoji: "🫘", position: styles.emojiPosition6 },
  ],
  2: [
    { emoji: "🪂", position: styles.emojiPosition2 },
    { emoji: "🪂", position: styles.emojiPosition3 },
    { emoji: "🫘", position: styles.emojiPosition4 },
    { emoji: "🌟", position: styles.emojiPosition5 },
    { emoji: "🪁", position: styles.emojiPosition6 },
  ],
  3: [
    { emoji: "🍀", position: styles.emojiPosition2 },
    { emoji: "🍀", position: styles.emojiPosition3 },
    { emoji: "🌭", position: styles.emojiPosition4 },
    { emoji: "💕", position: styles.emojiPosition5 },
    { emoji: "🫘", position: styles.emojiPosition6 },
  ],
  4: [
    { emoji: "🎉", position: styles.emojiPosition2 },
    { emoji: "🎉", position: styles.emojiPosition3 },
    { emoji: "🎉", position: styles.emojiPosition4 },
    { emoji: "🎉", position: styles.emojiPosition5 },
    { emoji: "🫘", position: styles.emojiPosition6 },
  ],
};

export const renderEmojis = (page: number) => {
  return emojiSets[page].map((item, index) => (
    <span key={index} className={`${styles.emoji} ${item.position}`}>
      {item.emoji}
    </span>
  ));
};
